import React, { Component } from 'react';
import animateScrollTo from 'animated-scroll-to';
import {SeoHeader, SeoImage} from "./Seo";
const logo = require('./assets/images/logo.png');

class Header extends Component {

    constructor(props){
        super(props);
        this.state = {isOpen:false};
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu(){
        this.setState({isOpen: !this.state.isOpen})
    }

    scrollTo(e,qr){
        e.preventDefault();
        animateScrollTo(document.querySelector(qr),{offset:-85});
    }

    render() {
        return (
            <header className="row fixed-top ">
                <div className="col-sm-5 col-lg-3 col-md-3 logo_bg">
                    <a className="navbar-brand" href="index.html">
                        <SeoImage src={logo} />
                        <SeoHeader></SeoHeader>
                    </a>
                    <button onClick={()=>{this.toggleMenu()}} className="Button">
                    </button>
                </div>
                <div className="col-sm-7 col-lg-9 col-md-9">
                    <div className="contact-txt">
                        <div className="top_contact">
                            <div className="contct_box"><span>Contact :</span> <a href={'tel:+919925870037'}>+91 99258 70037</a> | <a href={'tel:+919978780452'}>+91 99787 80452</a> | <a href={'tel:+919825847589'}>+91 98258 47589</a></div>
                            <div className="social_icon">
                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/HotelChitrakutVirpur/"><i className="fa fa-facebook-square"></i></a>
                                <a href="/" rel="noopener noreferrer"><i className="fa fa-google-plus-square"></i></a>
                            </div>
                        </div>
                    </div>
                    <nav className="navbar navbar-expand-lg">
                        <div  className={this.state.isOpen?"navbar-collapse":"collapse navbar-collapse"} id="navbarResponsive">

                            <ul className="navbar-nav ml-auto">
                                <li>
                                    <div className="d-lg-none d-md-none m-contact"><span> Contact :- </span> <a href={'tel:+919925870037'}>+91 99258 70037</a>  <br/> | <a href={'tel:+919978780452'}>+91 99787 80452</a> | <a href={'tel:+919825847589'}>+91 98258 47589</a></div>
                                </li>
                                <li className="nav-item">
                                    <a href="/" className="nav-link" onClick={(e)=>animateScrollTo(0)}>home</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/" className="nav-link" onClick={(e)=>this.scrollTo(e,'#aboutUs')}>about us</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/" className="nav-link" onClick={(e)=>this.scrollTo(e,'#rooms')}>room</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/" className="nav-link" onClick={(e)=>this.scrollTo(e,'#amenities')}>AMENITIES</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/" className="nav-link" onClick={(e)=>this.scrollTo(e,'#gallery')}>Gallery</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/" className="nav-link" onClick={(e)=>this.scrollTo(e,'#attractions')}>Attractions</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/" className="nav-link" onClick={(e)=>this.scrollTo(e,'#contactUs')}>contact us</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}

export default Header;
