import React, {Component} from 'react';
import {keywords} from "./Seo";

const items = [
    {
        src: require('./assets/images/slider1.jpg'),
        altText: '',
        caption: ''
    },
    {
        src: require('./assets/images/slider2.jpg'),
        altText: '',
        caption: ''
    },
    {
        src: require('./assets/images/slider3.jpg'),
        altText: '',
        caption: ''
    }
];

class Slider extends Component {

    componentDidMount() {
        new window.Swiper('.swiper-container', {
            slidesPerView: 1,
            lazy: true,
            loop: true,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }

    render() {
        const slides = items.map((item, index) => {
            return (
                <div className={'swiper-slide'} key={index}>
                    <img data-src={item.src}  alt={item.altText} className="swiper-lazy" />
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </div>
            );
        });

        return (
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    {slides}
                </div>
                <div className="swiper-pagination"></div>
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
            </div>
        )
    }
}

export default Slider;
