import React, {Fragment} from 'react';
import animateScrollTo from "animated-scroll-to";
import {aboutUs, newKeyWords, aboutJalaramBapa, aboutVirpurJalaram} from "./Seo";

class Footer extends React.Component{
    scrollTo(e,qr){
        e.preventDefault();
        animateScrollTo(document.querySelector(qr),{offset:-85});
    }
    render() {
        return (
            <Fragment>
            <footer className="bg-dark">
                    <div className="container">
                        <div className={'row'} style={{'marginTop':'45px'}}>
                           <div className="col-sm-3 col-lg-3">
                                <div className="ftr_cnt">
                                    <h3>CONTACT US</h3>
                                    <ul>
                                        <li>
                                            <i className="fa fa-map-marker"></i>
                                            <p>Opp. Jalaram Atithi Gruh, Railway station Road, Virpur (Jalaram) - 360380
                                            </p>
                                        </li>
                                        <li>
                                            <i className="fa fa-phone"></i>
                                            <p><a href={'tel:+919925870037'}>+91 99258 70037</a></p>
                                        </li>
                                        <li>
                                            <i className="fa fa-envelope"></i>
                                            <p><a href={'mailto:info@hotelchitrakut.in'}>info@hotelchitrakut.in</a></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-3 col-lg-3">
                                <div className="ftr_cnt">
                                    <h3>SITE MAP</h3>
                                    <ul className="quick_link">
                                        <li>
                                            <a href="/" onClick={(e)=>animateScrollTo(0)}>HOME</a>
                                        </li>
                                        <li>
                                            <a href="/" onClick={(e)=>this.scrollTo(e,'#aboutUs')}>ABOUT US</a>
                                        </li>
                                        <li>
                                            <a href="/" onClick={(e)=>this.scrollTo(e,'#rooms')}>ROOMS</a>
                                        </li>
                                        <li>
                                            <a href="/" onClick={(e)=>this.scrollTo(e,'#amenities')}>AMENITIES</a>
                                        </li>
                                        <li>
                                            <a href="/" onClick={(e)=>this.scrollTo(e,'#gallery')}>GALLERY</a>
                                        </li>
                                        <li>
                                            <a href="/" onClick={(e)=>this.scrollTo(e,'#attractions')}>ATTRACTIONS</a>
                                        </li>
                                        <li>
                                            <a href="/" onClick={(e)=>this.scrollTo(e,'#contactUs')}>CONTACT US</a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-6">
                                <div className="ftr_cnt">
                                    <h3>ABOUT US</h3>
                                    <p className={'footer-about-us'}>{aboutUs}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <section className={'bg-black copy-right'}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={'col-sm-12 col-lg-12'}>
                                <p className={'siteKeywords'}><strong>Who is The Saint Jalaram Bapa ?</strong><br/>{aboutJalaramBapa} <a href={'https://en.wikipedia.org/wiki/Jalaram_Bapa'}>more...</a></p>
                                <p className={'siteKeywords'}><strong>What is Virpur Jalaram ?</strong><br/>{aboutVirpurJalaram} <a href={'https://en.wikipedia.org/wiki/Virpur_(Rajkot)'}>more...</a></p>
                                <p className={'siteKeywords'}>{newKeyWords}</p>
                            </div>
                            <div className={'col-sm-12 col-lg-12 top-line'}>
                                <div className={'pull-left'}>
                                    <p>Hotel Chitrakut @ 1992 - {new Date().getFullYear()} All rights reserved</p>
                                </div>
                                <div className={'pull-right'}>
                                    <p>Developed & Maintain By <a target={'_blank'} href={'https://www.vedhaslabs.com'}>Vedhas Labs</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}


export default Footer;
