import React from 'react';
import Fade from 'react-reveal/Fade';
import {SeoHeader, SeoImage} from "./Seo";

const aboutImg = require('./assets/images/gallery/org/chitrakut-26.jpg');

class About extends React.Component{
    render(){
        return (
            <div className="container" id="aboutUs">
               <Fade bottom>
                <h1 className="main_heading text-center animation out" data-animation="fadeInUp" data-delay="0">Welcome to chitrakut</h1>
                </Fade > 
                <div className="row">
                    <div className="col-lg-6 col-sm-12 animation out" data-animation="fadeInLeft" data-delay="0">
                        
                        <div className="about_us_img">
                          <Fade left>
                              <SeoImage className="img-fluid" src={aboutImg}/>
                              <SeoHeader/>
                            </Fade>  
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 animation out" data-animation="fadeInRight" data-delay="0">
                    <Fade right>   
                        <div className="about_us">
                            <h2>About Us</h2>
                            <p>Hotel  Chitrakut is established  in 1992 and started the 
                            journey towards hospitality. Hotel Chitrakut is having more 
                            than 120 bed capacity which makes it largest Hotel in town.
                            it is having 24 rooms and 4 dormitories (general halls) which
                             makes it suitable and economical for school tours and sanghs
                            as 25 person can stay in each.Bed capacity varies from 2 bed/room 
                            to 7 bed/room with optional A/c and non A/c facilities.
                            each room is having hot/cold water availability for 24x7. </p> 
                            <p>
                            Jalram Bhojanalaya is located exactly opposite to hotel Chitrakut 
                            which is operated during festive season.
                            </p>

                            <span>
                            Hotel  Chitrakut is established  in 1992 and started the 
                            journey towards hospitality.
                            
                            </span>
                        </div>
                    </Fade>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default About;
