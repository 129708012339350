import React, {Component, Fragment} from 'react';
import Header from "./Header";
import Slider from "./Slider";
import About from "./About";
import BackToTop from "./BackToTop";
import Room from "./Room";
import Amenities from "./Amenities";
import OurVision from "./OurVision";
import Footer from "./Footer";
import Contact from "./Contact";
import PicGallery from "./PicGallery";
import TouristAttractions from './TouristAttractions';
import {SeoHeader} from "./Seo";

class App extends Component {
  render() {
    return (
        <Fragment>
          <Header />
          <Slider />
          <About  />
          <Room />
          <Amenities/>
          <OurVision/>
          <PicGallery/>
          <TouristAttractions/>
          <Contact />
          <Footer/>
          <BackToTop />
          <SeoHeader/>
        </Fragment>
    );
  }
}

export default App;
