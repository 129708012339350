import React from 'react';
import Fade from 'react-reveal/Fade';


class Contact extends React.Component{
    render() {
        return (
            <div id={'contactUs'} className="container">
                <div className="contact_main">
                    <div className="row">
                        <div className="col-sm-12 col-lg-7">
                        <Fade left>
                            <div className="map_box animated out" data-animation="fadeInUp" data-delay="0">
                                 <iframe
                                     title={'Hotel Chitrakut Google Map'}
                                     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1179.974296984361!2d70.70292976158191!3d21.84812937838523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39583defa11c237b%3A0x832b2c1edf8b83e4!2sHotel+Chitrakut!5e1!3m2!1sen!2sin!4v1541335000950"
                                     width="600" height="400" frameBorder="0" style={{border: 0}}
                                     allowFullScreen></iframe>
                            </div>
                        </Fade>
                        </div>
                        <div className="col-sm-12 col-lg-5">
                        <Fade bottom>
                            <div className="contect_box animated out" data-animation="fadeInDown" data-delay="0">
                                <span>GET IN TOUCH WITH US</span>
                                <h1 className="main_title black text-center">CONTACT US </h1>
                                <p>For Quick Reservations Or Enquiries don't hesitated to call us on our contact numbers at any time 24/7</p>
                                <form>
                                    <input type="text" className="input_txt" placeholder="Enter Your Name" />
                                    <input type="text" className="input_txt" placeholder="Enter Your Email" />
                                    <textarea className="input_txt area"
                                              placeholder="Type Your Message"></textarea>
                                    <input type="button" className="send_btn" value="SEND" />
                                </form>
                                <div className="social_icon_ftr">
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="https://www.facebook.com/HotelChitrakutVirpur/"><i
                                        className="fa fa-facebook-square"></i></a>
                                    <a href="/"><i className="fa fa-google-plus-square"></i></a>
                                </div>
                            </div>
                        </Fade>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    componentDidMount(){

    }
}


export default Contact;
