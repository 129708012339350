import React from 'react';
import Gallery from 'react-grid-gallery';
import {keywords, SeoHeader} from "./Seo";


const images = [
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-1.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-1.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-2.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-2.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-3.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-3.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-4.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-4.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-5.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-5.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-6.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-6.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-7.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-7.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-8.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-8.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-9.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-9.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-10.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-10.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-11.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-11.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-12.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-12.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-13.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-13.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-14.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-14.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-15.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-15.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-16.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-16.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-17.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-17.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-18.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-18.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-19.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-19.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-20.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-20.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-21.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-21.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-22.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-22.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-23.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-23.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-24.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-24.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-25.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-25.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-26.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-26.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        alt: keywords,
        src: require('./assets/images/gallery/org/chitrakut-27.jpg'),
        thumbnail: require('./assets/images/gallery/thumb/chitrakut-27.jpg'),
        thumbnailWidth: 320,
        thumbnailHeight: 174
    }
];

class PicGallery extends React.Component {
    render() {
        return (
            <section id={'gallery'} className="gallery_bg">
                <div className='container'>
                   <div className= 'row'>
                       <div className="col-sm-12 col-lg-12">
                    <h1 className="main_title black text-center">GALLERY</h1>
                           <SeoHeader/>
                    <Gallery images={images}/>
                    </div>
                  </div>
                </div>
            </section>    
        )
    }

}


export default PicGallery;
