import React from 'react';
import animateScroll from 'animated-scroll-to';
import * as anime from 'animejs';

class BackToTop extends React.Component{
    render(){
        return (
            <button id="myBtn" onClick={(e)=>{animateScroll(0, { minDuration: 1500 });}} title="Go to top">
                <i className="fa fa-chevron-up"></i>
            </button>
        )
    }

    componentDidMount(){
        window.onscroll = function()
        {
            const pageOffset =document.documentElement.scrollTop || document.body.scrollTop;
            if(pageOffset >= 100)
            {
                anime({
                    targets: '#myBtn',
                    opacity: 1
                })
            }else
            {
                anime({
                    targets: '#myBtn',
                    opacity: 0
                })
            }
        };

    }
    componentWillUnmount(){

    }
}

export default BackToTop;